import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "formDescriptionField", "descriptionField"];

  copyFormDescriptionField(event) {
    const submittedValue = this.formTarget.getAttribute("data-submitted-value") ?? "false";
    if (submittedValue == "true") return;

    event.preventDefault();

    setTimeout(() => {
      const descriptionFieldValue = this.descriptionFieldTarget.value.replace(/<[^>]*>/g, '').replace(/\&nbsp;/g, '');
      this.formDescriptionFieldTarget.value = descriptionFieldValue;
      this.formTarget.setAttribute("data-submitted-value", "true");
      this.formTarget.requestSubmit();
    }, 0);
  }

  feedProjectDescriptionToAssistant(event) {
    this.formDescriptionFieldTarget.value = this.descriptionFieldTarget.innerHTML;
  }
}
