import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link"];

  connect() {
    this.linkTargets.forEach((link) => {
      link.addEventListener("click", this.markActive.bind(this));
    });
  }

  markActive(event) {
    if (event.ctrlKey || event.shiftKey || event.metaKey) {
      return;
    }

    this.linkTargets.forEach((link) => {
      link.classList.remove("active", "bg-purple-100");
      link.classList.add("hover:bg-purple-100");
    });
    event.currentTarget.classList.add("active", "bg-purple-100");
    event.currentTarget.classList.remove("hover:bg-purple-100");
  }
}
