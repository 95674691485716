import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "files",
    "fileTemplate",
    "filesInput",
    "errorInput",
    "dropZone",
  ];

  connect() {
    this.files = [];
    const {
      fileFormatError,
      fileSizeError,
      supportedFormats,
      unsupportedFormats,
    } = this.errorInputTarget.dataset;
    this.errorLabels = {
      fileFormatError,
      fileSizeError,
    };
    this.supportedFormats = JSON.parse(supportedFormats || JSON.stringify([]));
    this.unsupportedFormats = JSON.parse(
      unsupportedFormats || JSON.stringify([])
    );
  }

  filesSelected(event) {
    const uploadedFiles = Array.from(event.target.files).sort(
      (fileA, fileB) => fileA.size - fileB.size
    );

    for (const file of uploadedFiles) {
      if (!this.isFileFormatValid(file)) {
        this.addError(this.errorLabels.fileFormatError);
        continue;
      }
      if (!this.isFileSizeValid(file)) {
        this.addError(this.errorLabels.fileSizeError);
        continue;
      }

      this.files.push(file);
      const templateDOM = this.fileTemplateTarget.cloneNode(true);
      const filenameSPAN = templateDOM.content.querySelector(".filename");
      const attachmentDIV = templateDOM.content.querySelector(".attachment");
      filenameSPAN.innerHTML = file.name;

      // Check if the file is an image
      if (file.type.startsWith("image/")) {
        // Create an object URL for the uploaded file
        const imageUrl = URL.createObjectURL(file);

        // Insert the image tag with the object URL as its source
        const imageTag = `<img src="${imageUrl}" class="uploaded-image w-full h-24 border-2 rounded" alt="${file.name}" />`;
        attachmentDIV.insertAdjacentHTML("afterbegin", imageTag);
      } else {
        // Insert a stub for non-image files
        const fileStub = `<div class="file-stub border-2 flex items-center justify-center rounded h-24"><p>${file.name
          .split(".")
          .pop()
          .toUpperCase()}</p></div>`;
        attachmentDIV.insertAdjacentHTML("afterbegin", fileStub);
      }

      this.filesTarget.innerHTML += templateDOM.innerHTML;
      this.removeError();
    }

    // using DataTransfer in order to not delete previously uploaded files
    const dataTransfer = new DataTransfer();
    this.files.forEach((file) => {
      dataTransfer.items.add(file);
    });
    this.filesInputTarget.files = dataTransfer.files;
  }

  isFileSizeValid(file) {
    const maxSizeInBytes = 200 * 1024 * 1024; // 200MB
    return file.size <= maxSizeInBytes;
  }

  isFileFormatValid(file) {
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (
      !!this.supportedFormats.length &&
      !this.supportedFormats.includes(fileExtension)
    ) {
      return false;
    }

    if (
      !!this.unsupportedFormats.length &&
      this.unsupportedFormats.includes(fileExtension)
    ) {
      return false;
    }

    return true;
  }

  removeError() {
    const errorTemplate = Object.assign(document.createElement("p"), {
      id: "attachment-validation-error",
      className: "form-validation-message",
    });
    errorTemplate.setAttribute("data-upload-target", "errorInput");

    this.errorInputTarget.replaceWith(errorTemplate);
    this.dropZoneTarget.classList.remove("error");
  }

  addError(textContent) {
    const errorMessage = Object.assign(document.createElement("p"), {
      className: "form-validation-message error",
      textContent,
    });
    errorMessage.setAttribute("data-upload-target", "errorInput");

    this.errorInputTarget.replaceWith(errorMessage);
    this.dropZoneTarget.classList.add("error");
  }

  remove(event) {
    event.preventDefault();

    // Get the file element and filename
    const fileElement = event.currentTarget.closest(".file");
    const filename = fileElement.querySelector(".filename").innerHTML;

    // Remove the file from the 'this.files' array
    this.files = this.files.filter((file) => file.name !== filename);

    // Update the 'filesInputTarget.files' using the 'this.files' array
    const dataTransfer = new DataTransfer();
    this.files.forEach((file) => {
      dataTransfer.items.add(file);
    });
    this.filesInputTarget.files = dataTransfer.files;

    // Remove the file element from the DOM
    fileElement.remove();
  }
}
