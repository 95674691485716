import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitBtn", "openModel"];

  replaceToSubmmit() {
    this.submitBtnTarget.classList.remove("hidden");
    this.submitBtnTarget.classList.add("btn-lg");

    this.openModelTarget.classList.add("hidden");
  }

  replaceToOpenModal() {
    this.openModelTarget.classList.remove("hidden");

    this.submitBtnTarget.classList.add("hidden");
    this.submitBtnTarget.classList.remove("btn-lg");
  }
}
