import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["alertWrapper"];

  connect() {
    this.alertId = this.element.dataset.alertId;
    if (localStorage.getItem(this.alertId)) {
      return;
    }

    this.element.classList.remove("hidden");
  }

  closeAlert() {
    this.markAsRead();

    this.alertWrapperTarget.classList.add("opacity-0", "transition-opacity", "duration-500");
    this.translateAllSibilings(this.alertWrapperTarget, true);

    setTimeout(() => {
      this.translateAllSibilings(this.alertWrapperTarget, false);
      this.alertWrapperTarget.remove();
    }, 500);
  }

  markAsRead() {
    if (this.alertId) {
      localStorage.setItem(this.alertId, true);
    }
  }

  translateAllSibilings(element, add_class) {
    let sibiling = element.nextElementSibling;

    while (sibiling) {
      if (sibiling.getAttribute('data-controller') === 'local-alerts') {
        if (add_class) {
          sibiling.classList.add("-translate-x-full", "duration-500");
        } else {
          sibiling.classList.remove("-translate-x-full", "duration-500");
        }

        sibiling = sibiling.nextElementSibling;
      } else {
        sibiling = null;
      }
    }
  }
}
