import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.dropZone = this.element;
    this.dropZone.addEventListener("dragover", this.handleDragOver.bind(this));
    this.dropZone.addEventListener(
      "dragleave",
      this.handleDragLeave.bind(this)
    );
    this.dropZone.addEventListener("drop", this.handleDrop.bind(this));
    this.filesUploadWrapper = this.element.dataset.filesUploadWrapperId;
  }

  handleDragOver(event) {
    event.preventDefault();
    this.dropZone.classList.add("drag-over");
  }

  handleDragLeave(event) {
    event.preventDefault();
    this.dropZone.classList.remove("drag-over");
  }

  handleDrop(event) {
    event.preventDefault();
    this.dropZone.classList.remove("drag-over");

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const uploadController =
        this.application.getControllerForElementAndIdentifier(
          document.getElementById(this.filesUploadWrapper),
          "upload"
        );
      uploadController.filesSelected({ target: { files } });
    }
  }
}
