/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs";
import { Turbo } from "@hotwired/turbo-rails";

import "chartkick/chart.js";

// Make accessible for Electron and Mobile adapters
window.Rails = Rails;
window.Turbo = Turbo;

require("@rails/activestorage").start();
import "@rails/actiontext";

// ActionCable Channels
import "./channels";

// Stimulus controllers
import "./controllers";

// Jumpstart Pro & other Functionality
import "./src/**/*";
require("local-time").start();

// Make text-area resizable
import autosize from "autosize/dist/autosize";
autosize(document.querySelectorAll("textarea"));

// move Trix attach-file button to the different place
document.addEventListener("trix-initialize", function (event) {
  document.querySelectorAll(".trix-button-row").forEach((row) => {
    const attachButton = row.querySelector(".trix-button--icon-attach");
    const textToolsGroup = row.querySelector(".trix-button-group--text-tools");

    if (attachButton && textToolsGroup) {
      textToolsGroup.appendChild(attachButton);
    }
  });

  if (event.target.classList.contains('llm-message-feedback')) {
    event.target.toolbarElement.remove();
    event.target.editor.element.setAttribute('contentEditable', false)
  }
});

Trix.config.textAttributes.llmTrixHighlight = {
  tagName: "llm-trix-highlight",
  inheritable: true
}

Trix.config.textAttributes.llmTrixHighlightBackground = {
  style: { backgroundColor: "#804dde", color: "#ffffff" },
  inheritable: true
}

// Start Rails UJS
Rails.start();

// Custom Turbo Stream Actions
Turbo.StreamActions.open_drawer = function () {
  const size = this.getAttribute('size')

  window.dispatchEvent(new CustomEvent('open_drawer', { "detail": { size: size }}))
};
