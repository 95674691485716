import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textarea", "send"]
  static values = {
    id: String
  }

  connect() {
    this.#setAutoSavedMessageFromLocalStorage();
    this.textareaTarget.addEventListener("keydown", this.handleKeyDown.bind(this));
    this.textareaTarget.focus();
  }

  disconnect() {
    this.textareaTarget.removeEventListener("keydown", this.handleKeyDown.bind(this));
  }

  handleKeyDown(event) {
    if (event.key === "Enter" && !event.shiftKey && !event.ctrlKey) {
      event.preventDefault();
      this.sendTarget.click();
    }
  }

  saveInLocalStorage(event) {
    localStorage.setItem(this.idValue, this.textareaTarget.innerHTML);
  }

  clearLocalStorage() {
    localStorage.removeItem(this.idValue)
  }

  #setAutoSavedMessageFromLocalStorage() {
    if(localStorage.getItem(this.idValue) == null)
      return;

    this.textareaTarget.value = localStorage.getItem(this.idValue);
  }
}
