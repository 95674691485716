import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "storyPoints", "budgetHours", "budgetMinutes", "pricePerStorypoint", "pricePerHour", "totalPayout", "totalFee", "totalCost", "paymentInfo", "timePaymentInfo", "biddingModeInfo" ]
  static values = {
    feerate: Number
  }

  timePaymentInfoTargetConnected() {
    this.calculateTimeBudget(null)
  }

  calculate(event) {
    const feePercent = this.feerateValue / 100.0
    const storyPoints = this.storyPointsTarget.value ?? 0
    const pricePerStorypoint = this.pricePerStorypointTarget.value ?? 0.0
    const totalPayout = storyPoints * pricePerStorypoint
    const totalFee = totalPayout * feePercent
    const totalCost = totalPayout + totalFee
    this.totalPayoutTarget.innerHTML = totalPayout.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    this.totalFeeTarget.innerHTML = totalFee.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    this.totalCostTarget.innerHTML = totalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }

  calculateWithoutFee(event) {
    const storyPoints = this.storyPointsTarget.value ?? 0
    const pricePerStorypoint = this.pricePerStorypointTarget.value ?? 0.0
    const totalCost = storyPoints *  pricePerStorypoint

    this.totalCostTarget.innerHTML = totalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }

  calculateTimeBudget(event) {
    const minutes = this.budgetMinutesTarget.value === '' ? 0 : this.budgetMinutesTarget.value
    const timeInMinutes = (this.budgetHoursTarget.value ?? 0) * 60.0 + (parseInt(minutes))
    const storyPoints = timeInMinutes / 15.0
    const pricePerStorypoint = (this.pricePerHourTarget.value ?? 0.0) / 4.0
    const totalCost = storyPoints * pricePerStorypoint

    this.totalCostTarget.innerHTML = totalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }

  toggleBiddingMode() {
    this.paymentInfoTarget.classList.toggle("hidden")
    this.biddingModeInfoTarget.classList.toggle("hidden")
  }
}
