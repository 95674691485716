import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  replaceBackButton() {
    document
      .getElementById("real_back_to_ai_results_btn")
      .classList.add("hidden");
    document
      .getElementById("fake_back_to_ai_results_btn")
      .classList.remove("hidden");
  }

  triggerSubmit() {
    document.getElementById("submit_new_task").click();
  }
}
