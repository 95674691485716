import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["star"];

  initialize() {
    this.currentRating =
      this.context.element.querySelector("input[checked]").value;
  }

  recalculateStarCount() {
    this.currentRating = event.target.value;
    this.lightTheStars();
  }

  recalculateStarCountWithSubmit() {
    this.recalculateStarCount();
    this.updateFeedbackRating();
  }

  lightTheStars() {
    this.context.element
      .querySelectorAll("svg:not(.filled-star)")
      .forEach((element, index) => {
        if (this.currentRating >= index + 1) {
          element.classList.add("hidden");
        } else {
          element.classList.remove("hidden");
        }
      });
    this.context.element
      .querySelectorAll("svg.filled-star")
      .forEach((element, index) => {
        if (this.currentRating >= index + 1) {
          element.classList.remove("hidden");
        } else {
          element.classList.add("hidden");
        }
      });
  }

  updateFeedbackRating() {
    event.target.closest("form").requestSubmit();
  }
}
