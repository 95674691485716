import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "storyPoints", "payoutPerStorypoint", "peerReviewEnabled", "engineerPayout", "platformMargin", "peerReviewCost", "peerReviewCostBlock", "taskBudget"]

  connect () {
    this.engineerRewardCoeff = this.data.get('engineerRewardCoeff')
    this.peerReviewPayoutCoefficient = 0.1
    this.payoutPerStorypoint = this.payoutPerStorypointTarget.getAttribute('data-value')
    this.calculate()
  }

  calculate(event) {
    let totalTaskBudget = 0
    const engineerPayout = this.storyPointsTarget.value * this.payoutPerStorypoint * this.engineerRewardCoeff
    const taskBudget = this.storyPointsTarget.value * this.payoutPerStorypoint
    const platformMargin = taskBudget - engineerPayout
    const peerReviewCost = taskBudget * this.peerReviewPayoutCoefficient
    if (this.peerReviewEnabledTarget.checked) {
      totalTaskBudget = taskBudget + peerReviewCost
      this.peerReviewCostBlockTarget.classList.remove('hidden')
    } else {
      totalTaskBudget = taskBudget
      this.peerReviewCostBlockTarget.classList.add('hidden')
    }

    this.engineerPayoutTarget.textContent = engineerPayout.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    this.platformMarginTarget.textContent = platformMargin.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    this.peerReviewCostTarget.textContent = peerReviewCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    this.taskBudgetTarget.textContent = totalTaskBudget.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }
}
