import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="squad-project-budget"
export default class extends Controller {
  static targets = ["spentOnTask", "reservedForTasks", "subprojectsApproximateBudgetsRest",
                    "subprojectsApproximateBudgetsRestAmount", "freeBudgetAmount", "approximateBudgetInput"]

  connect() {
    this.totalBudget = this.data.get("totalBudget")
    this.spentOnTasks = this.data.get("spentOnTasks")
    this.reservedForTasks = this.data.get("reservedForTasks")
    this.subprojectsApproximateBudgetsRest = this.data.get("subprojectsApproximateBudgetsRest")

    this.buildChart()
  }

  buildChart() {
    const division = this.totalBudget / 100
    const totalBudgetForTasks = parseFloat(this.spentOnTasks) + parseFloat(this.reservedForTasks)
    const availableBudget = this.totalBudget - totalBudgetForTasks
    let plannedToSpendAddition = 0

    if (this.hasApproximateBudgetInputTarget) {
      plannedToSpendAddition = parseFloat(this.approximateBudgetInputTarget.value) || 0
      if (plannedToSpendAddition < 0) { plannedToSpendAddition = 0 }
    }

    const subprojectsApproximateBudgetsRestAmount = parseFloat(this.subprojectsApproximateBudgetsRest) + plannedToSpendAddition
    const spentOnTasksPercentage = Math.ceil(this.spentOnTasks / division)
    const reservedForTasksPercentage = Math.round(this.reservedForTasks / division)
    const totalBudgetForTasksPercentage = spentOnTasksPercentage + reservedForTasksPercentage
    let freeBudgetAmount = this.freeBudgetAmountTarget.getAttribute("data-free-budget-amount")
    let subprojectsApproximateBudgetsRestPercentage = Math.round(subprojectsApproximateBudgetsRestAmount / division)

    freeBudgetAmount = freeBudgetAmount - plannedToSpendAddition
    if (freeBudgetAmount < 0) { freeBudgetAmount = 0 }

    if (subprojectsApproximateBudgetsRestAmount < 0) {
      const amount = this.formatMoney(subprojectsApproximateBudgetsRestAmount);

      this.subprojectsApproximateBudgetsRestAmountTarget.innerHTML = `<span class="text-red-500">${amount}</span>`
    } else if(subprojectsApproximateBudgetsRestAmount <= availableBudget) {
      this.subprojectsApproximateBudgetsRestAmountTarget.innerHTML = this.formatMoney(subprojectsApproximateBudgetsRestAmount)
    } else {
      const overBudgetAmount = subprojectsApproximateBudgetsRestAmount - availableBudget
      this.subprojectsApproximateBudgetsRestAmountTarget.innerHTML =
        `${this.formatMoney(subprojectsApproximateBudgetsRestAmount)} <span class="text-red-500">(${this.formatMoney(overBudgetAmount)} over budget)</span>`
    }
    this.freeBudgetAmountTarget.innerHTML = this.formatMoney(freeBudgetAmount)

    if (spentOnTasksPercentage > 0) {
      this.spentOnTaskTarget.style.display = "inline-block"
      this.spentOnTaskTarget.style.width = `${spentOnTasksPercentage}%`
    }
    if (reservedForTasksPercentage > 0) {
      this.reservedForTasksTarget.style.display = "inline-block"
      this.reservedForTasksTarget.style.width = `${reservedForTasksPercentage}%`
    }
    if (subprojectsApproximateBudgetsRestAmount > 0 && subprojectsApproximateBudgetsRestAmount <= availableBudget) {
      this.subprojectsApproximateBudgetsRestTarget.style.display = "inline-block"
      this.subprojectsApproximateBudgetsRestTarget.style.width = `${subprojectsApproximateBudgetsRestPercentage}%`
    } else if(subprojectsApproximateBudgetsRestAmount > availableBudget) {
      this.subprojectsApproximateBudgetsRestTarget.style.display = "inline-block"
      this.subprojectsApproximateBudgetsRestTarget.style.width = `${100 - totalBudgetForTasksPercentage}%`
    } else {
      this.subprojectsApproximateBudgetsRestTarget.style.display = "none"
    }
  }

  formatMoney(money) {
    return money.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }
}
