import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["aiPredictioBlock", "aiWidget"];

  connect() {
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target.offsetHeight < entry.target.scrollHeight) {
          this.aiWidgetTarget.classList.add("top-box-shadow");
        } else {
          this.aiWidgetTarget.classList.remove("top-box-shadow");
        }
      }
    });

    this.observe();
  }

  observe() {
    if (this.hasAiPredictioBlockTarget && this.hasAiWidgetTarget) {
      this.resizeObserver.observe(this.aiPredictioBlockTarget);
    }
  }

  disconnect() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
}
