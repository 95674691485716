import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tooltip", "addCommentButton"]

  addCommentButtonTargetConnected() {
    const editor = document.getElementById("feedback-message-body").editor;
    const tooltip = document.getElementById("add-comment-button-tooltip");
    const assistantId = document.getElementById("feedback_message_assistant_id").value
    const messageId = document.getElementById("feedback_message_id").value

    document.addEventListener("trix-selection-change", function (event) {
      if (event.target.classList.contains("llm-message-feedback")) {
        const range = editor.getSelectedRange()
        const selectedText = editor.getDocument().getStringAtRange(range).trim()
        const rect = editor.getClientRectAtPosition(range[0])
        const leftOffset = document.getElementById("drawer_content").offsetLeft
        const isAnythingReallySelected = window.getSelection().toString().trim().length > 0

        if (isAnythingReallySelected && selectedText.length > 0) {
          tooltip.style.left = `${rect.left - window.scrollX - leftOffset}px`;
          tooltip.style.top = `${rect.top + window.scrollY - 50}px`;
          tooltip.style.display = "block";
        } else {
          tooltip.style.display = "none";
        }
      }
    });

    document.getElementById("theAreaOppositeToEditor").addEventListener("click", function(event) {
      tooltip.style.display = "none";
    });

    this.addCommentButtonTarget.addEventListener("click", function(event) {
      const range = editor.getSelectedRange()
      const selectedText = editor.getDocument().getStringAtRange(range).trim()

      event.preventDefault();

      submitComment(range, selectedText);
    });

    async function submitComment(range, selectedText) {
      await fetch(`/llm/assistant/${assistantId}/messages/${messageId}/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",          
          "Accept": "application/json"
        },
        body: JSON.stringify({
          llm_comment: {
            range: range,
            highlight: selectedText,
            uuid: self.crypto.randomUUID()
          },
        }),
      })
      .then(response => {
        if (response.ok) {
          return true
        } else {
          document.getElementById("llm-feedback-finish-comment-alert")
                  .classList.remove("hidden");
        }
      })
    }
  }
}
