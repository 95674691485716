import { Controller } from "@hotwired/stimulus";

import Flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["dateFromInput", "dateToInput", "radioButton"];

  setDate(event) {
    const selectedRadioValue = event.target.value;

    const [dateFrom, dateTo] = {
      this_month: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(),
      ],
      last_month: [
        new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      ],
      last_6_months: [
        new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1),
        new Date(),
      ],
      this_year: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      last_year: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear() - 1, 11, 31),
      ],
    }[selectedRadioValue];

    const dateFromFlatpickrInstance = new Flatpickr(this.dateFromInputTarget);
    const dateToFlatpickrInstance = new Flatpickr(this.dateToInputTarget);

    dateFromFlatpickrInstance.setDate(dateFrom);
    dateToFlatpickrInstance.setDate(dateTo);
  }

  dateInputChange() {
    this.radioButtonTargets.forEach((radio) => {
      radio.checked = false;
    });
  }
}
