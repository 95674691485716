import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chat-tabs-nav"
export default class extends Controller {
  static targets = [ "navLink" ]

  switch(event) {
    this.navLinkTargets.forEach(target => {
      target.classList.remove("active");
    })

    event.currentTarget.classList.add("active");
  }
}
