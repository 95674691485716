// Custom TailwindCSS modals for confirm dialogs

const Rails = require("@rails/ujs")

// Cache a copy of the old Rails.confirm since we'll override it when the modal opens
const old_confirm = Rails.confirm;

// Elements we want to listen to for data-confirm
const elements = ['a[data-confirm]', 'button[data-confirm]', 'input[type=submit][data-confirm]']

const createConfirmModal = (element) => {
  var id = 'confirm-modal-' + String(Math.random()).slice(2, -1);
  var confirm = element.dataset.confirm
  var confirmTitle = element.dataset.confirm
  var confirmAction = element.dataset.confirmAction || 'Delete'
  var confirmActionType = element.dataset.confirmActionType || 'danger'

  if (element.dataset.confirmContent) {
    var confirmContent = `
      <div class="modal-content">
        <p class="mb-5">
          ${element.dataset.confirmContent}
        </p>
      </div>
    `
  } else {
    var confirmContent = ''
  }

  var content = `
    <div id="${id}" class="z-50 animated fadeIn fixed top-0 left-0 w-full h-full table" style="background-color: rgba(0, 0, 0, 0.8);">
      <span data-behavior="cancel" class="absolute top-0 left-0 h-full w-full"></span>
      <div class="modal-container">
        <div class="modal modal-sm">
          <div class="modal-header">
            <h1 class="modal-title">${confirmTitle}</h1>
            <button class="btn modal-close-btn">
              <div data-behavior="cancel" class="icon icon-xs icon-cross"></div>
            </button>
          </div>
          ${confirmContent}
          <div class="modal-footer">
            <div data-behavior="commit" class="btn-lg btn-${confirmActionType}">${confirmAction}</div>
            <div data-behavior="cancel" class="btn-lg btn-secondary close-btn">Cancel</div>
          </div>
        </div>
      </div>
    </div>
  `

  element.insertAdjacentHTML('afterend', content)

  var modal = element.nextElementSibling
  element.dataset.confirmModal = `#${id}`

  modal.addEventListener("keyup", (event) => {
    if(event.key === "Escape") {
      event.preventDefault()
      element.removeAttribute("data-confirm-modal")
      modal.remove()
    }
  })

  var cancelButtons = modal.querySelectorAll("[data-behavior='cancel']")
  cancelButtons.forEach(cancelButton => {
    cancelButton.addEventListener("click", (event) => {
      event.preventDefault()
      element.removeAttribute("data-confirm-modal")
      const dropdownActions = element.closest('.dropdown-actions')
      if (dropdownActions !== null) { dropdownActions.removeAttribute('open') }
      modal.remove()
    })
  })
  modal.querySelector("[data-behavior='commit']").addEventListener("click", (event) => {
    event.preventDefault()

    // Allow the confirm to go through
    Rails.confirm = () => { return true }

    // Click the link again
    element.click()

    // Remove the confirm attribute and modal
    element.removeAttribute("data-confirm-modal")
    Rails.confirm = old_confirm

    modal.remove()
  })

  modal.querySelector("[data-behavior='commit']").focus()
  return modal
}

// Checks if confirm modal is open
const confirmModalOpen = (element) => {
  return !!element.dataset.confirmModal;
}

const handleConfirm = (event) => {
  // If there is a modal open, let the second confirm click through
  if (confirmModalOpen(event.target)) {
    return true

  // First click, we need to spawn the modal
  } else {
    createConfirmModal(event.target)
    return false
  }
}

// When a Rails confirm event fires, we'll handle it
Rails.delegate(document, elements.join(', '), 'confirm', handleConfirm)
