import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import AbortController from "abort-controller";
import { promiseStatus, PROMISE_PENDING } from "promise-status-async";

export default class extends Controller {
  static targets = ["application", "question", "tabs"];

  tabsTargetConnected() {
    [
      "activeTab",
      "projectUuid",
      "applicantsCounter",
      "questionsCounter",
      "questionUpvotesCounter",
      "isDemoProject",
      "isEngineerAssigned",
    ].forEach((variable) => {
      this[variable] = this.tabsTarget.dataset[variable];
    });

    this.maxApplicantsCount = 3;
    this.maxQuestionsCount = 3;
    this.maxQuestionsUpvotesCount = 3;
    this.delay = 1500;
    this.intervals = [];
    this.currentRequest = Promise.resolve();

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;

    document.addEventListener(
      "turbo:frame-load",
      () => {
        this.selectNeededTrigger();
      },
      { once: true }
    );
  }

  changeTab(event) {
    this.activeTab = event.target.dataset.name;
  }

  selectNeededTrigger() {
    if (this.isDemoProject !== "true" || this.isEngineerAssigned === "true") {
      return;
    }

    if (this.questionTargets.length < this.maxQuestionsCount) {
      this.triggerNewQuestion();
    } else if (
      this.questionUpvotesCounter.length < this.maxQuestionsUpvotesCount
    ) {
      this.triggerQuestionUpVote();
    } else if (this.applicantsCounter.length < this.maxApplicantsCount) {
      this.triggerNewApplicant();
    }
  }

  abortRequests() {
    this.abortController.abort();
    this.intervals.forEach((interval) => {
      clearInterval(interval);
    });
  }

  triggerNewApplicant() {
    const interval = setInterval(async () => {
      if (this.applicantsCounter >= this.maxApplicantsCount) {
        clearInterval(interval);
      } else if (
        (await promiseStatus(this.currentRequest)) !== PROMISE_PENDING
      ) {
        this.currentRequest = post(
          `/client/demo_projects/${this.projectUuid}/create_invitation`,
          {
            responseKind: "turbo-stream",
            body: JSON.stringify({ current_tab: this.activeTab }),
            signal: this.signal,
          }
        );
        this.applicantsCounter++;
      }
    }, this.delay);
    this.intervals.push(interval);
  }

  triggerNewQuestion() {
    const interval = setInterval(async () => {
      if (this.questionsCounter >= this.maxQuestionsCount) {
        clearInterval(interval);
        this.triggerQuestionUpVote();
      } else if (
        (await promiseStatus(this.currentRequest)) !== PROMISE_PENDING
      ) {
        this.currentRequest = post(
          `/client/demo_projects/${this.projectUuid}/create_question`,
          {
            responseKind: "turbo-stream",
            body: JSON.stringify({ current_tab: this.activeTab }),
            signal: this.signal,
          }
        );
        this.questionsCounter++;
      }
    }, this.delay);
    this.intervals.push(interval);
  }

  triggerQuestionUpVote() {
    const interval = setInterval(async () => {
      if (this.questionUpvotesCounter >= this.maxQuestionsUpvotesCount) {
        clearInterval(interval);
        this.triggerNewApplicant();
      } else if (
        (await promiseStatus(this.currentRequest)) !== PROMISE_PENDING
      ) {
        this.currentRequest = post(
          `/client/demo_projects/${this.projectUuid}/create_question_upvote`,
          {
            responseKind: "turbo-stream",
            body: JSON.stringify({ current_tab: this.activeTab }),
            signal: this.signal,
          }
        );
        this.questionUpvotesCounter++;
      }
    }, this.delay);
    this.intervals.push(interval);
  }
}
