import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toast" ]

  toastTargetConnected(element) {
    setTimeout(() => {
      element.classList.add("opacity-0")
      setTimeout(() => {
        element.remove()
      }, 1000);
    }, 2500);
  }
}
