import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "validatable" ]

  validate() {
    this.validatableTargets.forEach((target) => {
      const parent = target.parentNode;
      if (target.value.length === 0) {
        target.classList.add("border-red-500");
        parent.nextElementSibling.classList.remove("hidden");
      } else {
        target.classList.remove("border-red-500");
        parent.nextElementSibling.classList.add("hidden");
      }
    });
  }
}
