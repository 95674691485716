import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  preventNewTab(event) {
    if (event.ctrlKey || event.shiftKey || event.metaKey) {
      event.preventDefault();
    }
  }

  preventContextMenu(event) {
    event.preventDefault();
  }
}
