import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  prefillTask(event) {
    const {
      taskTitle,
      taskKind,
      taskInstructions,
      taskStoryPoints,
      taskPeerReviewRequired,
    } = event.target.dataset;

    document.addEventListener(
      "turbo:frame-render",
      () => {
        document.getElementById("task_peer_review_required").checked =
          taskPeerReviewRequired == "true";
      },
      { once: true }
    );
  }
}
