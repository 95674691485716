import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["autoScrollMe"];

  autoScrollMeTargetConnected() {
    this.autoScrollMeTarget.children[this.autoScrollMeTarget.children.length - 1].scrollIntoView({ behavior: "smooth" })
  }

  acceptImprovement() {
    let editor = document.getElementById('project_configurable_attributes_requirements').editor
    let warning = document.getElementById('ai_generated_description_warning')
    let improvedDescription = document.getElementById('ai-improved-project-description')

    editor.recordUndoEntry('Content updated');
    editor.setSelectedRange([ 0, editor.getDocument().getLength() ]);
    editor.composition.replaceHTML(improvedDescription.innerHTML);
    warning.classList.remove('hidden');
  }
}
