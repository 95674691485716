import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "drawer"];
  static classes = ["defaultSize"];

  connect() {
    this.loadingContent = this.drawerTarget.innerHTML;
    this.sizes = {
      sm: ["w-full", "sm:w-11/12", "md:w-8/12", "lg:w-1/2", "xl:w-1/3"],
      md: ["w-full", "lg:w-2/3", "xl:w-1/2"],
      lg: ["w-full", "xl:w-2/3"],
      xl: ["w-full", "xl:w-4/5"],
    };

    window.addEventListener("open_drawer", (event) => this.open(event, false));

    this.makeRequestAndOpenIfNeeded();
  }

  setUrlToLocalstorage(event) {
    const { drawerHash, drawerUrl } = event.currentTarget.dataset;
    localStorage.setItem(drawerHash, drawerUrl);
  }

  makeRequestAndOpenIfNeeded() {
    const urlParams = new URLSearchParams(window.location.search);
    const hashkey = urlParams.get("open_drawer");
    const url = localStorage.getItem(hashkey);

    if (!url) {
      return;
    }

    this.open({ detail: { size: "sm" } });
    this.drawerTarget.setAttribute("src", url);
    localStorage.removeItem(hashkey);
  }

  open(event, interceptRender = true) {
    this.drawerTarget.classList = "drawer-content";
    const size = (event.params?.size || event.detail?.size) ?? "sm";
    this.drawerTarget.classList.add(...this.sizes[size]);
    if (interceptRender) {
      this.drawerTarget.innerHTML = this.loadingContent;
    }
    this.checkboxTarget.checked = true;
  }

  close(_event) {
    if (window.preventClosingDrawer) {
      return;
    }

    this.checkboxTarget.checked = false;
  }
}
