import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "tab" ]
  static classes = [ "backgrounded" ]

  select(event) {
    const selectedName = event.params.name;
    this.tabTargets.forEach((tab) => {
      const bar = tab.querySelector(".bar");
      if (tab.dataset.name == selectedName) {
        tab.classList.remove("text-gray-500", "hover:text-gray-700")
        tab.classList.add("text-gray-900")
        bar.classList.remove("bg-transparent")
        bar.classList.add(this.backgroundedClass ?? "bg-primary-500")
      } else {
        tab.classList.add("text-gray-500", "hover:text-gray-700")
        tab.classList.remove("text-gray-900")
        bar.classList.add("bg-transparent")
        bar.classList.remove(this.backgroundedClass ?? "bg-primary-500")
      }
    });
  }

  redesignSelect(event) {
    const selectedName = event.params.name;

    this.tabTargets.forEach((tab) => {
      if (tab.dataset.name == selectedName) {
        tab.classList.add("active");
      } else {
        tab.classList.remove("active");
      }
    });
  }
}
