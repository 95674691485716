import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["timeSpan"];
  static values = { time: String };

  connect() {
    const utcTime = new Date(this.timeValue);
    const localTime = new Date(
      utcTime.toLocaleString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );
    const today = new Date();
    const daysDifference = Math.floor(
      (today - localTime) / (1000 * 60 * 60 * 24)
    );

    let formattedTime;
    if (localTime.toDateString() === today.toDateString()) {
      formattedTime = localTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h23",
      });
    } else if (daysDifference < 7) {
      formattedTime = localTime.toLocaleDateString([], { weekday: "long" });
    } else {
      formattedTime = localTime.toLocaleDateString([], {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    }

    this.timeSpanTarget.innerHTML = formattedTime;
  }
}
