import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["btn", "checkbox"];

  handleCheckBoxClick(event) {
    // in case the button is an anchor tag, disable it by adding pointer-events-none class
    if (this.btnTarget.tagName == "A") {
      if (event.target.checked == true) {
        this.btnTarget.classList.remove("pointer-events-none");
      } else {
        this.btnTarget.classList.add("pointer-events-none");
      }
    } else {
      this.btnTarget.disabled = !event.target.checked;
    }
  }
}
