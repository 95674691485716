import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  formChanged = false;
  confirmedLeave = false;
  link = null;

  connect() {
    this.initialFormState = this.serializeForm(this.formTarget);
    this.beforeUnloadHandler = this.beforeUnload.bind(this);
    this.clickHandler = this.handleLinkClick.bind(this);
    this.turboBeforeRenderHandler = this.disconnect.bind(this);

    this.addListeners();
  }

  disconnect() {
    this.removeListeners();
  }

  addListeners() {
    window.addEventListener("beforeunload", this.beforeUnloadHandler);
    document.querySelectorAll("a").forEach((element) => {
      element.addEventListener("click", this.clickHandler);
    });
    document.addEventListener("turbo:load", () => {
      document.querySelectorAll("a").forEach((element) => {
        element.addEventListener("click", this.clickHandler);
      });
    });
    document.addEventListener(
      "turbo:before-render",
      this.turboBeforeRenderHandler
    );
  }

  removeListeners() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler);
    document.querySelectorAll("a").forEach((element) => {
      element.removeEventListener("click", this.clickHandler);
    });
    document.removeEventListener(
      "turbo:before-render",
      this.turboBeforeRenderHandler
    );
  }

  serializeForm(form) {
    return new FormData(form);
  }

  handleLinkClick(event) {
    const link = event.target.closest("a");
    if (!link || link.matches(".ignore-prevent-saving")) {
      return;
    }

    if (
      this.formChanged &&
      document.contains(this.formTarget) &&
      !this.confirmedLeave
    ) {
      event.preventDefault();
      this.link = link;
      this.showConfirmationModal();
    }
  }

  beforeUnload(event) {
    if (this.formChanged && !this.confirmedLeave) {
      event.preventDefault();
      event.returnValue = "";
    }
  }

  showConfirmationModal() {
    const hiddenModalController =
      this.application.getControllerForElementAndIdentifier(
        this.formTarget,
        "hidden-modal"
      );

    hiddenModalController.setModalContent();
    hiddenModalController.openModal({ preventDefault: () => ({}) });
  }

  confirmLeave() {
    this.confirmedLeave = true;
    this.removeListeners();

    window.open(this.link.href, this.link.target || "_self");
  }

  change() {
    if (document.contains(this.formTarget)) {
      const currentFormState = this.serializeForm(this.formTarget);
      this.formChanged = this.isFormChanged(currentFormState);
    }
  }

  isFormChanged(currentFormState) {
    for (let [key, value] of currentFormState.entries()) {
      if (this.initialFormState.get(key) !== value) {
        return true;
      }
    }
    return false;
  }
}
