import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["addable"];

  add() {
    const _addable = this.addableTarget;
    const _clone = _addable.cloneNode(true);
    _clone.classList.remove("hidden");
    _clone.removeAttribute("data-addition-target");
    _addable.parentNode.insertBefore(_clone, _addable);

    setTimeout(() => {
      _clone.querySelectorAll(".init-select").forEach((element) => {
        element.dataset.controller = "select";
      });
    }, 0);
  }

  remove(event) {
    const row = event.target.closest(".row");
    row.classList.add("hidden");
    row.querySelector(".delete-input").value = "1";
  }

  removeCompletely(event) {
    const row = event.target.closest(".row");
    row.remove();
  }
}
