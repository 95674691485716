import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["closeButton", "anotherController"];

  connect() {
    this.closeButtonTargets.forEach((el) => {
      el.addEventListener("click", function closeDetails() {
        this.closest("details").removeAttribute("open");
      });
    });
  }

  close(event) {
    event.target.closest("details").removeAttribute("open");
  }

  triggerAnotherController() {
    const { controllerTarget, controllerName, controllerAction } =
      this.anotherControllerTarget.dataset;
    if (!controllerTarget || !controllerAction) {
      return;
    }

    const anotherController =
      this.application.getControllerForElementAndIdentifier(
        document.getElementById(controllerTarget),
        controllerName
      );

    anotherController[controllerAction]();
  }
}
