import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "template", "dynamicField", "addButton"]

  connect() {
    this.optionsLength = this._getOptionsLength();
    this._updateSelectableOptions();
    this._toggleAddButton();
  }

  add() {
    this.containerTarget.appendChild(this._createDynamicField());
    this._toggleAddButton();
  }

  remove(event) {
    event.target.closest(".dynamic-field").remove();
    this._updateSelectableOptions();
    this._toggleAddButton();
  }

  itemSelected() {
    this._updateSelectableOptions();
  }

  _createDynamicField() {
    const dynamicField = this.templateTarget.cloneNode(true);
    const dynamicSelect = dynamicField.querySelector("select.dynamic-select");
    dynamicField.classList.remove("hidden");
    dynamicField.setAttribute("data-dynamic-select-tags-target", "dynamicField");
    dynamicSelect.setAttribute("data-controller", "select");
    return dynamicField;
  }

  _updateSelectableOptions() {
    const selects = this.containerTarget.querySelectorAll("select.dynamic-select");
    const tomSelectOptions = this.containerTarget.querySelectorAll(".option[role='option']");

    // When an options is selected in one of the selects, we need to hide it in the other selects.
    this._updateSelects(selects);
    this._updateTomSelectOptions(tomSelectOptions);
  }

  _updateSelects(selects) {
    selects.forEach(select => {
      if (this._shouldReplaceDynamicField(select)) {
        const dynamicField = select.closest(".dynamic-field");
        dynamicField.parentNode.replaceChild(this._createDynamicField(), dynamicField);
      } else {
        this._updateSelectOptions(select);
      }
    });
  }

  _shouldReplaceDynamicField(select) {
    return select.value === "" && select.getAttribute("data-controller") === "select";
  }

  _updateSelectOptions(select) {
    Array.from(select.options).forEach(option => {
      option.disabled = this.selectedValues.includes(option.value) && select.value !== option.value;
    });
  }

  _updateTomSelectOptions(tomSelectOptions) {
    tomSelectOptions.forEach(tomSelectOption => {
      const value = tomSelectOption.getAttribute("data-value");
      const isSelected = this.selectedValues.includes(value);

      tomSelectOption.toggleAttribute("data-selectable", !isSelected);
      tomSelectOption.setAttribute("aria-disabled", isSelected ? "true" : "false");
    });
  }

  _toggleAddButton() {
    this.addButtonTarget.disabled = this.optionsLength === 0 ||
                                    this.optionsLength === (this.dynamicFieldTargets.length - 1);
  }

  _getOptionsLength() {
    return Array.from(this.templateTarget.querySelector("select.dynamic-select").options)
      .filter(option => option.value !== "").length;
  }

  get selectedValues() {
    return this.dynamicFieldTargets
      .map(dynamicField => dynamicField.querySelector("select.dynamic-select").value)
      .filter(value => value !== "");
  }
}
