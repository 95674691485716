import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "storyPoints1", "storyPoints2", "storyPoints3", "pricePerStorypoint", "totalPayout1", "totalPayout2", "totalPayout3", "totalFee1", "totalFee2", "totalFee3", "totalCost1", "totalCost2", "totalCost3" ]
  static values = {
    feerate: Number
  }

  connect() {
    this.calculateWithoutFee(null)
  }

  calculate(event) {
    const feePercent = this.feerateValue / 100.0
    const pricePerStorypoint = this.pricePerStorypointTarget.value ?? 0.0

    const storyPoints1 = this.storyPoints1Target.innerHTML ?? 0
    const storyPoints2 = this.storyPoints2Target.innerHTML ?? 0
    const storyPoints3 = this.storyPoints3Target.innerHTML ?? 0

    const totalPayout1 = storyPoints1 * pricePerStorypoint
    const totalPayout2 = storyPoints2 * pricePerStorypoint
    const totalPayout3 = storyPoints3 * pricePerStorypoint

    const totalFee1 = totalPayout1 * feePercent
    const totalFee2 = totalPayout2 * feePercent
    const totalFee3 = totalPayout3 * feePercent

    const totalCost1 = totalPayout1 + totalFee1
    const totalCost2 = totalPayout2 + totalFee2
    const totalCost3 = totalPayout3 + totalFee3

    this.totalPayout1Target.innerHTML = totalPayout1.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    this.totalPayout2Target.innerHTML = totalPayout2.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    this.totalPayout3Target.innerHTML = totalPayout3.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

    this.totalFee1Target.innerHTML = totalFee1.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    this.totalFee2Target.innerHTML = totalFee2.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    this.totalFee3Target.innerHTML = totalFee3.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

    this.totalCost1Target.innerHTML = totalCost1.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    this.totalCost2Target.innerHTML = totalCost2.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    this.totalCost3Target.innerHTML = totalCost3.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }

  calculateWithoutFee(event) {
    const pricePerStorypoint = this.pricePerStorypointTarget.value ?? 0

    const storyPoints1 = this.storyPoints1Target.innerHTML ?? 0
    const storyPoints2 = this.storyPoints2Target.innerHTML ?? 0
    const storyPoints3 = this.storyPoints3Target.innerHTML ?? 0

    const totalCost1 = storyPoints1 * pricePerStorypoint
    const totalCost2 = storyPoints2 * pricePerStorypoint
    const totalCost3 = storyPoints3 * pricePerStorypoint

    this.totalCost1Target.innerHTML = totalCost1.toLocaleString('en-US', { minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
    this.totalCost2Target.innerHTML = totalCost2.toLocaleString('en-US', { minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
    this.totalCost3Target.innerHTML = totalCost3.toLocaleString('en-US', { minimumFractionDigits: 0, style: 'currency', currency: 'USD' })
  }
}
