import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import { Turbo } from "@hotwired/turbo-rails"; // Added import for Turbo

export default class extends Controller {
  static targets = ["form", "searchInput"];

  search(event) {
    event.preventDefault();

    const searchValue = this.searchInputTarget.value;
    const url = new URL(window.location);

    url.searchParams.set("search", searchValue);
    window.history.pushState({ path: url.href }, "", url.href);

    get(`${this.element.action}&search=${encodeURIComponent(searchValue)}`, {
      responseKind: "turbo-stream",
      headers: {
        useSearchTemplate: true,
      },
    }).then(() => Turbo.visit(url.href));
  }

  enterHandler(event) {
    if (event.keyCode != 13) {
      return;
    }

    this.search(event);
  }

  submitForm() {
    this.formTarget.submit();
  }
}
