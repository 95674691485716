import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const elementId = localStorage.getItem('elementId')
    if (elementId) {
      const element = document.getElementById(elementId)
      if (element) {
        element.scrollIntoView({ block: "center" })
      }
      localStorage.removeItem('elementId')
    }
  }

  saveElementId(event) {
    const elementId = event.target.closest('.scrollable').id
    localStorage.setItem('elementId', elementId)
  }
}
