import { Controller } from "@hotwired/stimulus"

const BACKSPACE_CODE = 8;

export default class extends Controller {
  static targets = [ "deletable", "markable" ]

  delete(event) {
    const element = event.target;
    if (event.type === "click" || (event.keyCode === BACKSPACE_CODE && element.value.length === 0)) {
      this.deletableTarget.classList.add("hidden");
    }
  }

  remove(event) {
    const element = event.target;
    if (event.type === "click" || (event.keyCode === BACKSPACE_CODE && element.value.length === 0)) {
      this.deletableTarget.remove();
    }
  }

  mark(event) {
    const element = event.target;
    const isBlank = element.value === undefined || element.value.trim().length === 0;
    this.markableTarget.value = isBlank ? "1" : "0";
  }
}
