import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mainSkills", "topSkills"];

  connect() {
    setTimeout(() => {
      this.updateOptions();
    }, 0);
  }

  updateOptions() {
    const selectedMainSkills = [...this.mainSkillsTarget.selectedOptions].map(
      (option) => ({
        value: option.value,
        text: option.textContent,
      })
    );

    const selectController =
      this.application.getControllerForElementAndIdentifier(
        this.topSkillsTarget,
        "select"
      );

    selectController.updateOptions(selectedMainSkills);

    const selectedTopSkills = [...this.topSkillsTarget.options]
      .filter((option) => option.selected)
      .map((option) => option.value);
    selectedTopSkills.forEach((skill) => {
      if (!selectedMainSkills.some((mainSkill) => mainSkill.value === skill)) {
        selectController.removeOption(skill);
      }
    });
  }
}
