import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template"];

  openModal(event) {
    event.preventDefault();

    document.getElementById("open_modal_btn").click();
  }

  setModalContent() {
    const templateDOM = this.templateTarget.content;
    const tempContainer = document.createElement("div");
    tempContainer.appendChild(templateDOM.cloneNode(true));

    document.getElementById("modal_content").innerHTML =
      tempContainer.innerHTML;
  }
}
