import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "projectChart",
    "projectChartData",
    "projectByStatusChart",
    "projectByStatusChartData",
    "taskDistributionChart",
    "taskDistributionChartData",
    "clientTaskDistributionChart",
    "clientTaskDistributionChartData",
  ];

  connect() {
    this.drawCharts();

    document.addEventListener(
      "turbo:load",
      () => {
        this.drawCharts();
      },
      { once: true }
    );
  }

  drawCharts() {
    this.createProjectChart();
    this.createProjectByStatusChart();
    this.createTaskDistributionChart();
    this.createClientTaskDistributionChart();
  }

  createProjectChart() {
    if (!this.hasProjectChartTarget) {
      return;
    }

    const targetData = this.projectChartDataTarget.dataset;
    const activeProjectsCount = +targetData.activeProjects;
    const completedProjectsCount = +targetData.completedProjects;
    if (!activeProjectsCount && !completedProjectsCount) {
      return;
    }

    const includeSquadProjects = targetData.includeSquadProjects === "true";

    const options = {
      colors: [
        "#FFAE2B",
        includeSquadProjects ? "#45B7B4" : undefined,
        "#1E88E5",
        "#B090EB",
      ].filter((e) => e),
      donut: true,
      legend: "left",
    };

    const data = [
      [targetData.streamProjectsTitle, targetData.streamProjectsValue],
      includeSquadProjects
        ? [targetData.squadProjectsTitle, targetData.squadProjectsValue]
        : undefined,
      [targetData.singleProjectsTitle, targetData.singleProjectsValue],
      [
        targetData.pairProgrammingProjectsTitle,
        targetData.pairProgrammingProjectsValue,
      ],
    ].filter((e) => e);

    this.projectChart = new Chartkick.PieChart(
      this.projectChartTarget,
      data,
      options
    );
  }

  createProjectByStatusChart() {
    if (!this.hasProjectByStatusChartTarget) {
      return;
    }

    const options = {
      colors: ["#1E88E5", "#0F912E"],
      donut: true,
      legend: "left",
    };
    const targetData = this.projectByStatusChartDataTarget.dataset;
    const activeProjectsCount = +targetData.activeProjects;
    const completedProjectsCount = +targetData.completedProjects;

    const data = [
      [targetData.activeProjectsTitle, activeProjectsCount],
      [targetData.completedProjectsTitle, completedProjectsCount],
    ];
    this.projectByStatusChart = new Chartkick.PieChart(
      this.projectByStatusChartTarget,
      data,
      options
    );
  }

  createTaskDistributionChart() {
    if (!this.hasTaskDistributionChartTarget) {
      return;
    }

    const targetData = this.taskDistributionChartDataTarget.dataset;
    const options = {
      colors: ["#9CB1C9", "#386491", "#8d88b5"],
    };

    const {
      backendTasksTitle,
      frontendTasksTitle,
      integrationTasksTitle,
      bugTypeTitle,
      featureTypeTitle,
      otherTypeTitle,
      backendBugTasksValue,
      frontendBugTasksValue,
      integrationBugTasksValue,
      backendFeatureTasksValue,
      frontendFeatureTasksValue,
      integrationFeatureTasksValue,
      backendOtherTasksValue,
      frontendOtherTasksValue,
      integrationOtherTasksValue,
      isDataPresent,
    } = targetData;

    if (isDataPresent === "false") {
      return;
    }

    const data = [
      {
        name: bugTypeTitle,
        data: {
          [backendTasksTitle]: backendBugTasksValue,
          [frontendTasksTitle]: frontendBugTasksValue,
          [integrationTasksTitle]: integrationBugTasksValue,
        },
      },
      {
        name: featureTypeTitle,
        data: {
          [backendTasksTitle]: backendFeatureTasksValue,
          [frontendTasksTitle]: frontendFeatureTasksValue,
          [integrationTasksTitle]: integrationFeatureTasksValue,
        },
      },
      {
        name: otherTypeTitle,
        data: {
          [backendTasksTitle]: backendOtherTasksValue,
          [frontendTasksTitle]: frontendOtherTasksValue,
          [integrationTasksTitle]: integrationOtherTasksValue,
        },
      },
    ];

    this.taskDistributionChart = new Chartkick.ColumnChart(
      this.taskDistributionChartTarget,
      data,
      options
    );
  }

  createClientTaskDistributionChart() {
    if (!this.hasClientTaskDistributionChartTarget) {
      return;
    }

    const targetData = this.clientTaskDistributionChartDataTarget.dataset;
    const options = {
      colors: ["#9CB1C9", "#386491", "#8d88b5"],
      legend: "left",
    };

    const {
      backendTasksTitle,
      frontendTasksTitle,
      integrationTasksTitle,
      bugTypeTitle,
      featureTypeTitle,
      otherTypeTitle,
      backendBugTasksValue,
      frontendBugTasksValue,
      integrationBugTasksValue,
      backendFeatureTasksValue,
      frontendFeatureTasksValue,
      integrationFeatureTasksValue,
      backendOtherTasksValue,
      frontendOtherTasksValue,
      integrationOtherTasksValue,
      isDataPresent,
    } = targetData;

    if (isDataPresent === "false") {
      return;
    }

    const data = [
      {
        name: bugTypeTitle,
        data: {
          [backendTasksTitle]: backendBugTasksValue,
          [frontendTasksTitle]: frontendBugTasksValue,
          [integrationTasksTitle]: integrationBugTasksValue,
        },
      },
      {
        name: featureTypeTitle,
        data: {
          [backendTasksTitle]: backendFeatureTasksValue,
          [frontendTasksTitle]: frontendFeatureTasksValue,
          [integrationTasksTitle]: integrationFeatureTasksValue,
        },
      },
      {
        name: otherTypeTitle,
        data: {
          [backendTasksTitle]: backendOtherTasksValue,
          [frontendTasksTitle]: frontendOtherTasksValue,
          [integrationTasksTitle]: integrationOtherTasksValue,
        },
      },
    ];

    this.taskDistributionChart = new Chartkick.ColumnChart(
      this.clientTaskDistributionChartTarget,
      data,
      options
    );
  }
}
