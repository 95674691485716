import { Controller } from "@hotwired/stimulus"
import HillChart from "hill-chart"

export default class extends Controller {
  static targets = [ "svg", "percentageInput", "percentageOutput" ]
  static values = {
    width: Number,
    height: Number,
    percentage: Number,
    label: String
  }

  connect() {
    const data = [
      {
        color: '#5C20D6',
        description: this.labelValue,
        size: 9,
        x: this.percentageValue
      }
    ]
    const config = {
      target: this.svgTarget,
      width: this.widthValue,
      height: this.heightValue,
      preview: false
    }
    this.hill_chart = new HillChart(data, config)
    this.hill_chart.render()

    this.hill_chart.on("move", this._move.bind(this))
  }

  _move(x, y) {
    const percentageRounded = parseInt(x, 10)
    this.percentageValue = percentageRounded
    this.percentageInputTarget.value = percentageRounded
    this.percentageOutputTarget.textContent = percentageRounded
  }
}
