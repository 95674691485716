import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "teamLeaderBudgetField", "teamLeaderBudget", "platformMargin", "teamLeaderReward"]

  connect () {
    this.teamLeaderRewardCoeff = this.data.get('eworkMarginPercentage') / 100
    this.calculate()
  }

  calculate() {
    const teamLeaderBudget = parseFloat(this.teamLeaderBudgetFieldTarget.value || 0)
    const platformMargin = teamLeaderBudget * this.teamLeaderRewardCoeff
    const teamLeaderReward = teamLeaderBudget - platformMargin

    this.teamLeaderBudgetTarget.textContent = teamLeaderBudget.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    this.platformMarginTarget.textContent = platformMargin.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    this.teamLeaderRewardTarget.textContent = teamLeaderReward.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }
}
