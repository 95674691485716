import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  confirmedLeave = false;
  drawerContentChanged = false;

  connect() {
    this.beforeUnloadHandler = this.beforeUnload.bind(this);
    this.turboBeforeRenderHandler = this.disconnect.bind(this);
    this.drawerBackground = this.getDrawerBackground();

    this.addListeners();
  }

  disconnect() {
    this.removeListeners();
  }

  addListeners() {
    window.addEventListener("beforeunload", this.beforeUnloadHandler);
    document.addEventListener(
      "turbo:before-render",
      this.turboBeforeRenderHandler
    );
  }

  removeListeners() {
    window.preventClosingDrawer = false;
    window.removeEventListener("beforeunload", this.beforeUnloadHandler);
    document.removeEventListener(
      "turbo:before-render",
      this.turboBeforeRenderHandler
    );
  }

  change() {
    this.drawerContentChanged = true;
    window.preventClosingDrawer = true;
    this.preventClosingDrawer();
  }

  beforeUnload(event) {
    if (this.drawerContentChanged && !this.confirmedLeave) {
      event.preventDefault();
      event.returnValue = "";
    }
  }

  preventDefaultBehaviour(event) {
    event.preventDefault();
    document.getElementById("open_modal_btn").click();
  }

  preventClosingDrawer() {
    [...document.getElementsByClassName("prevent-closing-drawer")].forEach(
      (element) => {
        element.addEventListener("click", this.preventDefaultBehaviour);
      }
    );
    this.drawerBackground.addEventListener(
      "click",
      this.preventDefaultBehaviour
    );
  }

  returnDrawerBehaviour() {
    this.drawerBackground.removeEventListener(
      "click",
      this.preventDefaultBehaviour
    );
  }

  confirmLeave() {
    window.preventClosingDrawer = false;
    this.confirmedLeave = true;
    this.returnDrawerBehaviour();
    this.removeListeners();
    this.drawerBackground.click();
  }

  getDrawerBackground() {
    return document.getElementsByClassName("drawer-background")[0];
  }
}
