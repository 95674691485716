import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="highlight"
export default class extends Controller {
  static targets = ["button"]
  static outlets = ["highlight"]

  highlightOutletDisconnected() {
    if (!this.highlightOutlets.length) {
      this.buttonTarget.disabled = true;
    } else {
      this.buttonTarget.disabled = false;
    }
  }

  disableButton() {
    this.buttonTarget.disabled = true;
  }

  enableButton() {
    if (this.highlightOutlets.some(comment => comment.hasEditTarget)) {
      return;
    } else {
      this.buttonTarget.disabled = false;
    }
  }
}
