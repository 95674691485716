import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "removable" ]

  cancel() {
    this.removableTargets.forEach(target => {
      target.remove();
    })
  }
}
