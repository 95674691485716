import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "list", "empty" ]

  connect() {
    this.check()
    this.timer = setInterval(this.check.bind(this), 200)
  }

  disconnect() {
    clearInterval(this.timer)
  }

  check() {
    if (this.listTarget.textContent.trim() === '') {
      this.emptyTarget.classList.remove("hidden")
    } else {
      this.emptyTarget.classList.add("hidden")
    }
  }
}
