import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="highlight"
export default class extends Controller {
  static targets = ["description", "floatingBar", "comment", "cancel", "edit"]
  static outlets = ["send-feedback-button"]

  static values = {
    range: Array,
    uuid: String
  }

  range = this.rangeValue.map((number) => { return parseInt(number) });
  editor = document.getElementById("feedback-message-body").editor;

  outsideViewportIndicatorDown = document.getElementById("highlightIsOutsideOfViewportDown")
  outsideViewportIndicatorUp = document.getElementById("highlightIsOutsideOfViewportUp")

  commentTargetConnected() {
    this.highlightRange()

    this.commentTarget.addEventListener("mouseover", (event) => this.focusRange(event));
    this.commentTarget.addEventListener("mouseout", (event) => this.unfocusRange(event));
  }

  editTargetConnected() {
    this.sendFeedbackButtonOutlet.disableButton();
  }

  // this one is fired only every second time
  // the reason why is unknown to me
  // probably the lizard overlords are messing with us
  editTargetDisconnected() {
    this.sendFeedbackButtonOutlet.enableButton();
  }

  highlightRange() {
    this.editor.setSelectedRange(this.range);
    this.editor.activateAttribute("llmTrixHighlight");
    this.editor.setSelectedRange(0);
  }

  deHighlightRange() {
    this.editor.setSelectedRange(this.range);
    this.editor.deactivateAttribute("llmTrixHighlight");
    this.editor.setSelectedRange(0);
  }

  reHighlightRange(_event, uuid) {
    if (uuid !== this.uuidValue) {
      this.highlightRange();
    }
  }

  focusRange() {
    this.editor.setSelectedRange(this.range);
    this.editor.activateAttribute("llmTrixHighlightBackground");
    this.editor.setSelectedRange(0);

    // if the highlight is outside of the viewport, show an indicator
    const rect = this.editor.getClientRectAtPosition(this.range[0])
    if (rect.top >= (window.innerHeight || document.documentElement.clientHeight)) {
      this.outsideViewportIndicatorDown.classList.remove("hidden");
    };
    if (rect.bottom < 60) { // 60 is the height of the header
      this.outsideViewportIndicatorUp.classList.remove("hidden");
    };
  }

  unfocusRange() {
    this.editor.setSelectedRange(this.range);
    this.editor.deactivateAttribute("llmTrixHighlightBackground");
    this.editor.setSelectedRange(0);

    // if the indicator (highlight is outside of the viewport) is visible, hide it
    this.outsideViewportIndicatorUp.classList.add("hidden");
    this.outsideViewportIndicatorDown.classList.add("hidden");
  }
}
