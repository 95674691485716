import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";

export default class extends Controller {
  static targets = ["slider", "wrapper"];

  connect() {
    this.disabledWrapperClass = "disabled";
  }

  async makeRequest() {
    const checkbox = this.sliderTarget;
    const wrapper = this.wrapperTarget;

    const previousState = checkbox.checked;
    const taskId = checkbox.dataset.taskId;

    wrapper.classList.add(this.disabledWrapperClass);
    checkbox.disabled = true;

    try {
      const response = await patch(`${taskId}/toggle_in_trouble`);
      if (!response.ok) {
        checkbox.checked = !previousState;
      }
    } catch {
      checkbox.checked = !previousState;
    } finally {
      checkbox.disabled = false;
      wrapper.classList.remove(this.disabledWrapperClass);
    }
  }
}
