// Reconnect ActionCable after switching accounts

import { Toggle } from "tailwindcss-stimulus-components";

export default class extends Toggle {
  static targets = ["hideable", "showable", "dynamic"];

  openValueChanged() {
    this.toggleClass &&
      this.hideableTargets.forEach((target) => {
        target.classList.remove(this.toggleClass);
        if (this.openValue === false) target.classList.add(this.toggleClass);
      });

    this.toggleClass &&
      this.showableTargets.forEach((target) => {
        target.classList.remove(this.toggleClass);
        if (this.openValue === true) target.classList.add(this.toggleClass);
      });
  }

  toggle(_event) {
    this.dynamicTargets.forEach((target) => {
      target.classList.toggle("hidden");
    });
  }
}
