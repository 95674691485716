import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.targetButton = document.getElementById("submit_milestone_button")
  }

  toggleSubmitButtonDisabled() {
    let textarea_length = document.getElementsByClassName("milestone-edit-input").length

    if (textarea_length > 0) {
      this.targetButton.disabled = false
    } else {
      this.targetButton.disabled = true
    }
  }

  submitButtonEnabled() {
    this.targetButton.disabled = false
  }
}
