import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";

export default class extends Controller {
  edit(event) {
    event.preventDefault();
    this.uploadImage();
  }

  delete(event) {
    event.preventDefault();
    if (confirm("Are you sure you want to delete avatar?")) {
      this.deleteImage();
    }
  }

  uploadImage() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.addEventListener("change", this.handleFileSelect.bind(this));
    input.click();
  }

  handleFileSelect(event) {
    const file = event.target.files[0];
    if (file) {
      this.uploadToServer(file);
    }
  }

  uploadToServer(file) {
    const form = new FormData();
    form.append("user[avatar]", file);

    put("/engineer/profile", { responseKind: "turbo-stream", body: form });
  }

  deleteImage() {
    const form = new FormData();
    form.append("user[delete_avatar]", true);

    put("/engineer/profile", { responseKind: "turbo-stream", body: form });
  }
}
