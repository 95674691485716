import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "tab" ]

  select(event) {
    const selectedName = event.params.name
    this.tabTargets.forEach(tab => {
      const bar = tab.querySelector(".bar")
      if (tab.dataset.name == selectedName) {
        tab.classList.add("active")
      } else {
        tab.classList.remove("active")
      }
    })
  }
}
