import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "wrapper", "content", "toggler" ]

  connect() {
    if (this.contentTarget.offsetHeight <= this.wrapperTarget.offsetHeight) {
      this.wrapperTarget.classList.remove("bottom-faded")
      this.togglerTarget.classList.add("hidden")
    }
  }

  toggle(event) {
    event.preventDefault()
    if (this.wrapperTarget.classList.contains("bottom-faded")) {
      const height = this.contentTarget.offsetHeight
      this.wrapperTarget.classList.remove("bottom-faded")
      this.wrapperTarget.style = "height: " + height + "px;"
    } else {
      this.wrapperTarget.classList.add("bottom-faded")
      this.wrapperTarget.style = ""
    }
  }
}
